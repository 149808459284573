<template lang="html" src="./index.template.vue"></template>

<style lang="scss" src="./index.scss"></style>

<script>
const i18nData = require('./index.i18n');

export default {
  name: 'PageIndex',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {};
  },
};
</script>
