var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-index"
  }, [_c('div', {
    staticClass: "text-center align-middle",
    attrs: {
      "id": "welcomeTitle"
    }
  }, [_c('p', {
    staticClass: "display-3 text-primary",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('welcome'))
    }
  }), _c('v-row', {
    staticClass: "logo",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("../../assets/logo_blue.png"),
      "alt": "logo",
      "max-width": "30%"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }